bilzin.ng.directive("ngBioHero", ['$window', function ($window) {
  return function (scope, element, attrs) {
    var $element = $(element);
    var $image = $($element.find('.js-bio-image'));
    var $section = $($element.find('.js-q-and-a'));
    var $bioHero = $($element.find('.js-bio-hero'));
    var $bioHeroInfo = $($element.find('.js-bio-hero .contact-info'));
    var $bioHeroQuote = $($element.find('.js-bio-hero .quote'));

    updatePosition();

    angular.element($window).bind("resize", function () {
      updatePosition();
    });

    function updatePosition() {
      if ($window.innerWidth > 1250) {
        var height = $image.outerHeight(true);
        var heroHeight = $bioHero.outerHeight(true);
        if ($section.length > 0) {
          $section.css('margin-top', (height - heroHeight) + 15);
        }
        if ($bioHeroQuote.length > 0) {
          if (height > heroHeight) {
            $bioHeroQuote.css("margin-top", "140px");
          }
          else
            $bioHeroQuote.css('margin-top', 0);
        }
      } else if ($window.innerWidth > 645) {
        var height = $image.outerHeight(true);
        var heroHeight = $bioHeroInfo.outerHeight(true);
        if ($section.length > 0) {
          if ($bioHeroQuote.length === 0) {
            $section.css('margin-top', (height - heroHeight) - 15);
          } else {
            if (height > heroHeight) {
              $bioHeroQuote.css("margin-top", "4em");
              $section.css('margin-top', 0);
            }
            else
              $section.css('margin-top', 0);
          }
        }

        if ($bioHeroQuote.length > 0) {
          if (height > heroHeight) {
            $bioHeroQuote.css("margin-top", "4em");
          }
          else
            $bioHeroQuote.css('margin-top', height - heroHeight);
        }

      } else {
        if ($bioHeroQuote.length > 0) {
          $bioHeroQuote.css('margin-top', 0);
        }
      }
    }
  };
}]);