bilzin.ng.controller("WebinarsController", ['$scope', '$http', '$location', 'queryStringService', 'watchUrlService', function ($scope, $http, $location, queryStringService, watchUrlService) {
  $scope.type = 0;
  $scope.isLoading = true;
  $scope.isLoadingMore = false;

  $scope.upcomingResults = [];
  $scope.upcomingPageNum = 0;
  $scope.upcomingTake = 0;
  $scope.upcomingSkip = 0;
  $scope.hasMoreUpcoming = false;

  $scope.recordedResults = [];
  $scope.recordedPageNum = 0;
  $scope.recordedTake = 0;
  $scope.recordedSkip = 0;
  $scope.hasMoreRecorded = false;

  var eventTypes = [];
  var queryString = '';

  watchUrlService.watchStateChange($scope, search);

  $scope.setValues = function (jsonModel, isinitial) {

    if (jsonModel.EventTypes) {
      eventTypes = jsonModel.EventTypes;
    }

    if (jsonModel.Upcoming && $scope.type === 3) {
      setUpcomingValues(jsonModel.Upcoming);
    } else if (jsonModel.Recorded && $scope.type === 4) {
      setRecordedValues(jsonModel.Recorded);
    } else {
      setUpcomingValues(jsonModel.Upcoming);
      setRecordedValues(jsonModel.Recorded);
    }

    $scope.isLoading = false;
  };

  function setUpcomingValues(jsonResult) {
    if ($scope.isLoadingMore) {
      $scope.upcomingResults = $scope.upcomingResults.concat(jsonResult.SearchResults);
      $scope.isLoadingMore = false;
    } else {
      if ($scope.upcomingSkip > 0 && $scope.upcomingResults.length > $scope.upcomingSkip) {
        //back button request so slice off the last concat
        $scope.upcomingResults = $scope.upcomingResults.slice(0, $scope.upcomingSkip);
      } else {
        $scope.upcomingResults = jsonResult.SearchResults;
      }
    }
    $scope.hasMoreUpcoming = jsonResult.HasMoreResults;
    $scope.upcomingTake = jsonResult.SearchFilters.Take;
    $scope.upcomingSkip = jsonResult.SearchFilters.Skip;
  }

  function setRecordedValues(jsonResult) {
    if ($scope.isLoadingMore) {
      $scope.recordedResults = $scope.recordedResults.concat(jsonResult.SearchResults);
      $scope.isLoadingMore = false;
    } else {
      if ($scope.recordedSkip > 0 && $scope.recordedResults.length > $scope.recordedSkip) {
        //back button request so slice off the last concat
        $scope.recordedResults = $scope.recordedResults.slice(0, $scope.recordedSkip);
      } else {
        $scope.recordedResults = jsonResult.SearchResults;
      }
    }
    $scope.hasMoreRecorded = jsonResult.HasMoreResults;
    $scope.recordedTake = jsonResult.SearchFilters.Take;
    $scope.recordedSkip = jsonResult.SearchFilters.Skip;
  }

  $scope.viewMoreUpcoming = function () {
    $scope.type = 3;
    $scope.performSearch(true, true);
  };

  $scope.viewMoreRecorded = function () {
    $scope.type = 4;
    $scope.performSearch(true, true);
  };

  $scope.performSearch = function (hasSearched, isLoadMore) {
    $scope.isLoading = true;

    if ($scope.type === 0 || $scope.type === 3) {
      if (isLoadMore) {
        $scope.isLoadingMore = true;
        $scope.upcomingPageNum++;
        $scope.upcomingSkip = $scope.upcomingPageNum * $scope.upcomingTake;
      } else {
        $scope.upcomingPageNum = 0;
        $scope.upcomingSkip = 0;
      }
    }
    if ($scope.type === 0 || $scope.type === 4) {
      if (isLoadMore) {
        $scope.isLoadingMore = true;
        $scope.recordedPageNum++;
        $scope.recordedSkip = $scope.recordedPageNum * $scope.recordedTake;
      } else {
        $scope.recordedPageNum = 0;
        $scope.recordedSkip = 0;
      }
    }

    queryString = '';
    queryString = queryStringService.addQueryString('upcomingskip', $scope.upcomingSkip, queryString);
    queryString = queryStringService.addQueryString('upcomingtake', ($scope.type === 0 || $scope.type === 3) ? $scope.upcomingTake : 0, queryString);

    queryString = queryStringService.addQueryString('recordedskip', $scope.recordedSkip, queryString);
    queryString = queryStringService.addQueryString('recordedtake', ($scope.type === 0 || $scope.type === 4) ? $scope.recordedTake : 0, queryString);

    queryString = queryStringService.addQueryString('type', $scope.type, queryString);

    if (eventTypes) {
      queryString = queryStringService.addQueryString('eventTypes', eventTypes, queryString);
    }

    $location.search(queryString);
  }

  function search() {
    $http.get('/api/sitecore/webinars/EventTypeSearch?' + queryStringService.toQueryString($location.search()))
      .success(function (response) {
        $scope.setValues(response);
      }).error(function (data, status, headers, config) {

      }
      );
  }

  function updateFiltersFromQueryString(queryStringObject, searchFilters) {

  }
}]);