bilzin.ng.controller("GlobalSiteSearchController", ['$scope', '$http', '$element', '$location', 'queryStringService', 'watchUrlService', 'typeAheadUrlService', function ($scope, $http, $element, $location, queryStringService, watchUrlService, typeAheadUrlService) {
  $scope.isLoading = true;
  $scope.isLoadingMore = false;
  $scope.sectionTake = 0;
  $scope.fullTake = 0;
  $scope.SelectedFilters = [];

  var watchUrl = ($element.attr('data-watch-url') === 'true');

  if (watchUrl) { watchUrlService.watchStateChange($scope, search); }

  $scope.setValues = function (jsonResult, fullTake, sectionTake) {
    $scope.viewModel = jsonResult;
    $scope.sectionTake = sectionTake;
    $scope.fullTake = fullTake;
    $scope.isLoading = false;

    typeAheadUrlService.extend($scope, jsonResult.TypeAheadResult)

    $scope.typeAheadViewModel.keyword = $scope.viewModel.SearchFilters.KeywordFilter;

    setSelectedFilters();
  };

  $scope.typeChange = function () {
    $scope.viewModel.SearchFilters.ShowViewAll = false;
    $scope.performSearch(false);
  };

  $scope.keywordChange = function () {
    $scope.viewModel.SearchFilters.SearchType = null;
    $scope.viewModel.SearchFilters.KeywordFilter = $scope.typeAheadViewModel.keyword;
    $scope.clearTypeAheadInput();
    $scope.performSearch(false);
  };

  $scope.viewAllByType = function (type) {
    $scope.viewModel.SearchFilters.SearchType = type;
    $scope.viewModel.SearchFilters.ShowViewAll = false;
    $scope.performSearch(false);
    return false
  };

  $scope.viewMore = function () {
    $scope.performSearch(true);
    return false;
  };

  $scope.cleanAllFilters = function () {
    $scope.typeAheadViewModel.keyword = null;
    $scope.viewModel.SearchFilters.KeywordFilter = null;
    $scope.viewModel.SearchFilters.SearchType = null;
    $scope.performSearch(false);
    return false;
  };

  $scope.performSearch = function (isLoadMore) {
    $scope.isLoading = true;
    $scope.viewModel.SearchFilters.KeywordFilter = $scope.typeAheadViewModel.keyword;
    activeKeyword = $scope.viewModel.SearchFilters.KeywordFilter;

    if (isLoadMore) {
      $scope.isLoadingMore = true;
      $scope.viewModel.SearchFilters.Page++;
    } else {
      $scope.isLoadingMore = false;
      $scope.viewModel.SearchFilters.Page = 1;
    }

    queryString = '';

    queryString = queryStringService.addQueryString('page', $scope.viewModel.SearchFilters.Page, queryString);

    if ($scope.viewModel.SearchFilters.SearchType != null && $scope.viewModel.SearchFilters.SearchType != '') {
      $scope.viewModel.SearchFilters.PageSize = $scope.fullTake;
      queryString = queryStringService.addQueryString('type', $scope.viewModel.SearchFilters.SearchType, queryString);
    } else {
      $scope.viewModel.SearchFilters.PageSize = $scope.sectionTake;
    }

    queryString = queryStringService.addQueryString('pagesize', $scope.viewModel.SearchFilters.PageSize, queryString);

    if ($scope.viewModel.SearchFilters.KeywordFilter != null && $scope.viewModel.SearchFilters.KeywordFilter != '') {
      queryString = queryStringService.addQueryString('keyword', encodeURIComponent($scope.viewModel.SearchFilters.KeywordFilter), queryString);
    }

    if (watchUrl) $location.search(queryString);
    else queryStringSearch();
  }

  function search() {
    $http.get('/api/sitecore/sitesearch/search?' + queryStringService.toQueryString($location.search()))
      .success(function (response) {
        success(response);
      }).error(function (data, status, headers, config) {
        $scope.isLoading = false;
        $scope.isLoadingMore = false;
        console.log("data", data, "status", status)
      }
      );
  }

  function queryStringSearch() {
    $http.get('/api/sitecore/sitesearch/search?' + queryString)
      .success(function (response) {
        success(response);
      }).error(function (data, status, headers, config) {
        $scope.isLoading = false;
        $scope.isLoadingMore = false;
        console.log("data", data, "status", status)
      }
      );
  }

  function success(response) {
    applySearchResults($scope.viewModel.SearchResultAll, response.SearchResultAll);
    applySearchResults($scope.viewModel.SearchResultPeople, response.SearchResultPeople);
    applySearchResults($scope.viewModel.SearchResultExperience, response.SearchResultExperience);
    applySearchResults($scope.viewModel.SearchResultInsights, response.SearchResultInsights);
    applySearchResults($scope.viewModel.SearchResultPractices, response.SearchResultPractices);

    $scope.viewModel.TotalResultCount = $scope.viewModel.SearchResultAll.ResultCount +
      $scope.viewModel.SearchResultPeople.ResultCount +
      $scope.viewModel.SearchResultExperience.ResultCount +
      $scope.viewModel.SearchResultInsights.ResultCount +
      $scope.viewModel.SearchResultPractices.ResultCount

    setSelectedFilters()

    $scope.isLoading = false;
    $scope.isLoadingMore = false;

    $scope.viewModel.SearchFilters.ShowViewAll = (($scope.viewModel.SearchResultPeople.ResultCount > 0 ? 1 : 0) +
      ($scope.viewModel.SearchResultExperience.ResultCount > 0 ? 1 : 0) +
      ($scope.viewModel.SearchResultInsights.ResultCount > 0 ? 1 : 0) +
      ($scope.viewModel.SearchResultPractices.ResultCount > 0 ? 1 : 0)) > 1
      ;
  }

  function applySearchResults(scopeResult, jsonSearchResult) {
    if ($scope.isLoadingMore) {
      scopeResult.SearchResults = scopeResult.SearchResults.concat(jsonSearchResult.SearchResults);
    } else {
      scopeResult.SearchResults = jsonSearchResult.SearchResults;
    }

    scopeResult.SearchFilters = jsonSearchResult.SearchFilters;
    scopeResult.HasMoreResults = jsonSearchResult.HasMoreResults;
    scopeResult.ResultCount = jsonSearchResult.ResultCount;
  }

  function setSelectedFilters() {
    $scope.SelectedFilters = []
    if ($scope.viewModel.SearchFilters.SearchType != null && $scope.viewModel.SearchFilters.SearchType != '') {
      var selectedFilter = $scope.viewModel.SearchFilters.TypeFilters.find(x => x.ID == $scope.viewModel.SearchFilters.SearchType)
      if (!!selectedFilter) {
        selectedFilter.removeFilter = function () {
          $scope.viewModel.SearchFilters.SearchType = null
          $scope.performSearch(false)
        }
        $scope.SelectedFilters.push(selectedFilter)
      }
    }
    if ($scope.viewModel.SearchFilters.KeywordFilter != null && $scope.viewModel.SearchFilters.KeywordFilter != '') {
      var selectedFilter = {
        ID: 'Keyword',
        Name: $scope.viewModel.SearchFilters.KeywordFilter
      }
      selectedFilter.removeFilter = function () {
        $scope.viewModel.SearchFilters.KeywordFilter = null
        $scope.typeAheadViewModel.keyword = null
        $scope.performSearch(false)
      }
      $scope.SelectedFilters.push(selectedFilter)
    }
  }

  $scope.formatNumber = function (num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
}]);