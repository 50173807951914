bilzin.ng.controller("ExperiencesController", ['$scope', '$http', '$element', '$location', 'queryStringService', 'watchUrlService', function ($scope, $http, $element, $location, queryStringService, watchUrlService) {
  $scope.keyword = '';
  $scope.selectedFilterObjects = [];
  $scope.type = 0;
  $scope.isLoading = true;
  $scope.isLoadingMore = false;

  $scope.largeStoryResults = [];
  $scope.largeStoryPageNum = 0;
  $scope.largeStoryTake = 0;
  $scope.largeStorySkip = 0;
  $scope.hasMoreStories = false;

  $scope.showAll = false;
  $scope.results = [];
  $scope.pageNum = 0;
  $scope.take = 0;
  $scope.skip = 0;
  $scope.hasMore = false;
  $scope.expanded = false;

  var activeKeyword = '';
  var queryString = '';

  watchUrlService.watchStateChange($scope, search);

  $scope.setValues = function (jsonModel, isinitial) {
    if (jsonModel.Experience && $scope.type === 1) {
      setExperienceValues(jsonModel.Experience);
      updateFiltersFromQueryString($location.search(), jsonModel.Experience.SearchFilters);
    } else if (jsonModel.LargerStories && $scope.type === 2) {
      setLargerStoryValues(jsonModel.LargerStories);
      updateFiltersFromQueryString($location.search(), jsonModel.LargerStories.SearchFilters);
    } else {
      setExperienceValues(jsonModel.Experience);
      setLargerStoryValues(jsonModel.LargerStories);
      updateFiltersFromQueryString($location.search(), jsonModel.LargerStories.SearchFilters);
    }

    //clearing keyword in code doesn't trigger onblur
    $('.searchbox-input').blur();

    $scope.isLoading = false;
  };

  function setExperienceValues(jsonResult) {
    if ($scope.isLoadingMore) {
      $scope.results = $scope.results.concat(jsonResult.SearchResults);
      $scope.isLoadingMore = false;
    } else {
      $scope.results = jsonResult.SearchResults;
    }
    $scope.hasMore = jsonResult.HasMoreResults;
    $scope.take = jsonResult.SearchFilters.Take;
    $scope.skip = jsonResult.SearchFilters.Skip;
  }

  function setLargerStoryValues(jsonResult) {
    if ($scope.isLoadingMore) {
      $scope.largeStoryResults = $scope.largeStoryResults.concat(jsonResult.SearchResults);
      $scope.isLoadingMore = false;
    } else {
      $scope.largeStoryResults = jsonResult.SearchResults;
    }
    $scope.hasMoreStories = jsonResult.HasMoreResults;
    $scope.largeStoryTake = jsonResult.SearchFilters.Take;
    $scope.largeStorySkip = jsonResult.SearchFilters.Skip;
  }

  $scope.removeFilter = function (idx) {
    if (idx > -1 && idx < $scope.selectedFilterObjects.length) {
      switch ($scope.selectedFilterObjects[idx].Type) {
        case 'keyword':
          $scope.keyword = '';
          break;
      }
      $scope.selectedFilterObjects.splice(idx, 1);
      $scope.type = 0;
      $scope.performSearch(true);
    }
  };

  $scope.removeAllFilters = function () {
    $scope.selectedFilterObjects = [];
    $scope.keyword = '';
    activeKeyword = '';
    $scope.type = 0;
    $scope.performSearch(true);
  };

  $scope.performKeywordSearch = function () {
    //remove pre-existing topic filter on keyword search
    $scope.selectedFilterObjects = [];

    var idx = getTypeIndex('keyword');

    if (idx > -1) {
      $scope.selectedFilterObjects[idx].ID = $scope.keyword;
      $scope.selectedFilterObjects[idx].Name = $scope.keyword;
    } else {
      $scope.selectedFilterObjects.push({ 'ID': $scope.keyword, 'Name': $scope.keyword, 'Type': 'keyword', 'IsSelected': true, 'IsEnabled': true });
    }
    $scope.type = 0;
    $scope.performSearch(true);
  }

  $scope.viewMore = function () {
    $scope.type = 1;
    $scope.performSearch(true, true);
  };

  $scope.viewMoreLargeStories = function () {
    $scope.type = 2;
    $scope.performSearch(true, true);
  };

  $scope.performSearch = function (hasSearched, isLoadMore) {
    $scope.isLoading = true;
    activeKeyword = $scope.keyword;

    if ($scope.type === 0 || $scope.type === 1) {
      if (isLoadMore) {
        $scope.isLoadingMore = true;
        $scope.pageNum++;
        $scope.skip = $scope.pageNum * $scope.take;
      } else {
        $scope.pageNum = 0;
        $scope.skip = 0;
      }
    }
    if ($scope.type === 0 || $scope.type === 2) {
      if (isLoadMore) {
        $scope.isLoadingMore = true;
        $scope.largeStoryPageNum++;
        $scope.largeStorySkip = $scope.largeStoryPageNum * $scope.largeStoryTake;
      } else {
        $scope.largeStoryPageNum = 0;
        $scope.largeStorySkip = 0;
      }
    }

    queryString = '';
    queryString = queryStringService.addQueryString('skip', $scope.skip, queryString);
    queryString = queryStringService.addQueryString('take', $scope.take, queryString);

    queryString = queryStringService.addQueryString('storyskip', $scope.largeStorySkip, queryString);
    queryString = queryStringService.addQueryString('storytake', $scope.largeStoryTake, queryString);

    queryString = queryStringService.addQueryString('type', $scope.type, queryString);

    for (var i = 0; i < $scope.selectedFilterObjects.length; i++) {
      queryString = queryStringService.addQueryString($scope.selectedFilterObjects[i].Type, $scope.selectedFilterObjects[i].ID, queryString);
    }

    $location.search(queryString);
  }

  function search() {
    $http.get('/api/sitecore/experiences/search?' + queryStringService.toQueryString($location.search()))
      .success(function (response) {
        success(response);
      }).error(function (data, status, headers, config) {

      });
  }

  function success(response) {
    $scope.setValues(response);
    $scope.hasSearched = $scope.hasSearched || $scope.selectedFilterObjects.length > 0 || activeKeyword.length > 0;
  }

  function updateFiltersFromQueryString(queryStringObject, searchFilters) {
    $scope.selectedFilterObjects = [];
    if ("keyword" in queryStringObject) {
      $scope.keyword = queryStringObject.keyword;
      if (!hasFilterType('keyword')) {
        $scope.selectedFilterObjects.push({ 'ID': $scope.keyword, 'Name': $scope.keyword, 'Type': 'keyword', 'IsSelected': true, 'IsEnabled': true });
      } else {
        $scope.selectedFilterObjects.push({ 'ID': $scope.keyword, 'Name': $scope.keyword, 'Type': 'keyword', 'IsSelected': true, 'IsEnabled': true });
      }
    }
    if ("type" in queryStringObject) {
      $scope.type = parseInt(queryStringObject.type);
    }
  }

  function hasFilterType(type) {
    for (var i = 0; i < $scope.selectedFilterObjects.length; i++) {
      if ($scope.selectedFilterObjects[i].Type === type) return true;
    }
    return false;
  }

  function getTypeIndex(type) {
    for (var i = 0; i < $scope.selectedFilterObjects.length; i++) {
      if ($scope.selectedFilterObjects[i].Type === type) return i;
    }
    return -1;
  }

  function isValidFilter(filter) {
    return (filter && filter.ID);
  }
}]);