bilzin.ng.factory("typeAheadUrlService", ['$http', function ($http) {
  return {
    extend: function (currentScope, jsonResult) {
      currentScope.typeAheadViewModel = jsonResult;
      currentScope.typeAheadViewModel.keyword = ''

      currentScope.hasFocus = false;
      currentScope.hasSearched = false;

      var timerid = null;

      currentScope.typeAheadKeyup = function (keycode) {
        if (keycode !== 13 && currentScope.typeAheadViewModel.keyword.length > 2) {
          currentScope.hasFocus = true;
          if (!!timerid) {
            clearTimeout(timerid);
          }
          timerid = setTimeout(PopulateTypeAhead(), 100);
        } else if (currentScope.typeAheadViewModel.keyword.length <= 2) {
          currentScope.typeAheadViewModel.TypeAheadItems = [];
          currentScope.hasSearched = false;
        }
      };

      currentScope.navigateToSearchPage = function () {
        document.location = currentScope.typeAheadViewModel.SearchUrl + '?keyword=' + encodeURIComponent(currentScope.typeAheadViewModel.keyword);
      };

      currentScope.encodedTypeAheadKeyword = function () {
        return encodeURIComponent(currentScope.typeAheadViewModel.keyword);
      };

      currentScope.clearTypeAheadInput = function () {
        setTimeout(function () {
          currentScope.hasFocus = false;
          currentScope.$apply();
        }, 100);
      }

      currentScope.setTypeAheadFocus = function () {
        currentScope.hasFocus = true;
      }

      function PopulateTypeAhead() {
        $http.get(currentScope.typeAheadViewModel.TypeAheadUrl + '?keyword=' + encodeURIComponent(currentScope.typeAheadViewModel.keyword))
          .success(function (response) {
            typeAheadSuccess(response);
          }).error(function (data, status, headers, config) {
            currentScope.typeAheadViewModel.TypeAheadItems = [];
            currentScope.hasSearched = false;
          }
          );
      }

      function typeAheadSuccess(response) {

        for (var i = 0; i < response; i++) {
          var item = null;
          var name = list[i].Name.toLowerCase();
          var simplename = "";
          if (list[i].SimpleName != null) {
            simplename = list[i].SimpleName.toLowerCase();
          }

          if (name.indexOf(currentScope.typeAheadViewModel.keyword.toLowerCase()) != -1) {
            item.Name = highlight(item.Name, currentScope.typeAheadViewModel.keyword);
          }
          else if ((simplename != "" && simplename.indexOf(currentScope.typeAheadViewModel.keyword.toLowerCase()) != -1)) {
            var namehighlight = name.substr(simplename.indexOf(currentScope.typeAheadViewModel.keyword.toLowerCase()), currentScope.typeAheadViewModel.keyword.length);
            item.Name = highlight(item.Name, namehighlight);
          }
        }
        currentScope.typeAheadViewModel.TypeAheadItems = response;
        currentScope.hasSearched = true;
      }

      function highlight(data, search) {
        return data.replace(new RegExp("(" + preg_quote(search) + ")", 'gi'), "<b>$1</b>");
      }

      function preg_quote(str) {
        return (str + '').replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, "\\$1");
      }
    }
  };
}]);