bilzin.ng.factory("domHelper", ['$window', function ($window) {
  return {
    scrollTo: function (scrollPos, offset) {
      $window.addEventListener('wheel', preventDefault);
      $('html, body').stop().animate({
        scrollTop: scrollPos + offset
      }, {
        duration: 1000,
        complete: function () {
          $window.removeEventListener('wheel', preventDefault);
        }
      });
    },

    startPanelUI: function ($element) {
      $panelWrapper = $($element).find('.js-panels');
      $panelWrapper.addClass('is-transition-out').css('height', $panelWrapper.height());
    },

    finishPanelUI: function ($element, show, hide) {
      $panelWrapper = $($element).find('.js-panels');
      $showpanel = $panelWrapper.find(show);
      $hidepanel = $panelWrapper.find(hide);

      $hidepanel.removeClass('is-selected')
      $showpanel.addClass('is-selected')

      $panelWrapper.removeClass('is-transition-out')
        .animate({
          height: $showpanel.outerHeight()
        }, 300, function () {
          $panelWrapper.css('height', '');
        });
    }
  };

  function preventDefault(evt) { evt.preventDefault(); }
}]);