bilzin.ng.controller("SiteSearchController", ['$scope', '$http', '$element', '$location', 'queryStringService', 'domHelper', 'watchUrlService', function ($scope, $http, $element, $location, queryStringService, domHelper, watchUrlService) {
  $scope.keyword = '';
  $scope.topicId = '';
  $scope.selectedFilterObjects = [];
  $scope.type = 0;
  $scope.isLoading = true;
  $scope.isLoadingMore = false;
  $scope.results = [];
  $scope.pageNum = 0;
  $scope.take = 0;
  $scope.skip = 0;
  $scope.hasMore = false;
  $scope.resultsCount = 0;
  $scope.keywordRegex = '';
  var activeKeyword = '';
  var queryString = '';

  watchUrlService.watchStateChange($scope, search);

  $scope.setValues = function (jsonResult) {
    //clearing keyword in code doesn't trigger onblur
    $('.searchbox-input').blur();
    $scope.topicId = jsonResult.SearchFilters.SelectedTopic;
    $scope.topicName = jsonResult.SearchFilters.SelectedTopicName;
    updateFiltersFromQueryString($location.search(), jsonResult.SearchFilters);

    $scope.keyword = jsonResult.SearchFilters.KeywordFilter;
    $scope.keywordRegex = getKeywordsSearchedRegex(jsonResult.SearchFilters);
    getReleventText(jsonResult.SearchResults, $scope.keywordRegex);

    if ($scope.isLoadingMore) {
      $scope.results = $scope.results.concat(jsonResult.SearchResults);
      $scope.isLoadingMore = false;
    } else {
      $scope.results = jsonResult.SearchResults;
    }

    $scope.hasMore = jsonResult.HasMoreResults;
    $scope.resultsCount = jsonResult.ResultCount;
    $scope.take = jsonResult.SearchFilters.Take;
    $scope.skip = jsonResult.SearchFilters.Skip;
    $scope.isLoading = false;

    domHelper.finishPanelUI($element, $scope.type == 1 ? '.js-panel-default' : '.js-panel-ajax', $scope.type == 1 ? '.js-panel-ajax' : '.js-panel-default');
  };

  $scope.performKeywordSearch = function () {
    updateTitleAndH1();
    $scope.topicId = '';
    $scope.selectedFilterObjects = [];
    $scope.skip = 0;
    if ($scope.keyword !== '') {
      $scope.selectedFilterObjects.push({ 'ID': encodeURIComponent($scope.keyword), 'Name': $scope.keyword, 'Type': 'keyword', 'IsSelected': true, 'IsEnabled': true });
    }
    $scope.performSearch(true);
  };

  $scope.filterByType = function (type) {
    if ($scope.type !== type) {
      domHelper.startPanelUI($element);
      $scope.skip = 0;
      $scope.type = type;
      $scope.performSearch(true);
    }
  };

  $scope.viewMore = function () {
    $scope.performSearch(true, true);
  };

  $scope.removeFilter = function (idx) {
    if (idx > -1 && idx < $scope.selectedFilterObjects.length) {
      $scope.keyword = '';
      $scope.selectedTopic = {};
      $scope.topicId = '';
      $scope.selectedFilterObjects.splice(idx, 1);
      $scope.performSearch(true);
    }
  };

  $scope.removeAllFilters = function () {
    $scope.selectedFilterObjects = [];
    $scope.keyword = '';
    $scope.topicId = '';
    $scope.topicName = '';
    $scope.skip = 0;
    $scope.performSearch(true);
  };

  $scope.performSearch = function (hasSearched, isLoadMore) {
    $scope.isLoading = true;
    activeKeyword = $scope.keyword;

    if (isLoadMore) {
      $scope.isLoadingMore = true;
      $scope.pageNum++;
      $scope.skip = $scope.pageNum * $scope.take;
    } else {
      $scope.pageNum = 0;
      $scope.skip = 0;
    }

    queryString = '';
    queryString = queryStringService.addQueryString('skip', $scope.skip, queryString);
    queryString = queryStringService.addQueryString('take', $scope.take, queryString);
    queryString = queryStringService.addQueryString('type', $scope.type, queryString);

    for (var i = 0; i < $scope.selectedFilterObjects.length; i++) {
      queryString = queryStringService.addQueryString($scope.selectedFilterObjects[i].Type, $scope.selectedFilterObjects[i].ID, queryString);
    }

    if ($scope.topicId !== '') {
      queryString = queryStringService.addQueryString('topic', $scope.topicId, queryString);
    }

    $location.search(queryString);
  };

  $scope.hasMatchingText = function (collection) {
    var collectionString = collection.join(' ');
    if (this.keywordRegex && this.keywordRegex.test(collectionString))
      return true;
    return false;
  }

  function search() {
    $http.get('/api/sitecore/sitesearch/search?' + queryStringService.toQueryString($location.search()))
      .success(function (response) {
        success(response);
      }).error(function (data, status, headers, config) {

      }
      );
  }

  function success(response) {
    $scope.setValues(response);
    $scope.hasSearched = $scope.hasSearched || $scope.selectedFilterObjects.length > 0 || activeKeyword.length > 0;
  }

  function updateTitleAndH1() {
    document.title = 'Bilzin Sumberg';
    var h1Breadcrumb = document.querySelector('h1.nav-menu-item');
    var spanBreadcrumb = document.querySelector('span.nav-menu-item');
    if (h1Breadcrumb && spanBreadcrumb) {
      h1Breadcrumb.innerHTML = '';
      spanBreadcrumb.innerHTML = spanBreadcrumb.innerHTML.replace(': ', '');
    }
  }

  function updateFiltersFromQueryString(queryStringObject, searchFilters) {
    $scope.selectedFilterObjects = [];
    if ("keyword" in queryStringObject) {
      $scope.keyword = decodeURIComponent(queryStringObject.keyword);
      $scope.selectedFilterObjects.push({ 'ID': encodeURIComponent($scope.keyword), 'Name': $scope.keyword, 'Type': 'keyword', 'IsSelected': true, 'IsEnabled': true });
    }
    if ("topic" in queryStringObject) {
      $scope.topicId = queryStringObject.topic;
      //$scope.selectedFilterObjects.push({ 'ID': $scope.topicId, 'Name': $scope.topicName, 'Type': 'topic', 'IsSelected': true, 'IsEnabled': true });
    }
    if ("type" in queryStringObject) {
      $scope.type = parseInt(queryStringObject.type);
    }
  }

  function hasFilterType(type) {
    for (var i = 0; i < $scope.selectedFilterObjects.length; i++) {
      if ($scope.selectedFilterObjects[i].Type === type) return true;
    }
    return false;
  }

  function getReleventText(results, keywordRegex) {
    for (var i = 0; i < results.length; i++) {
      var name = results[i].Name;
      var textArray = results[i].ReleventText.split('|');

      if (keywordRegex) {

        if (keywordRegex.test(name)) {
          results[i].Name = name.replace(keywordRegex, function (match) { return '<span class="search-results-highlight">' + match + '</span>'; });
        }

        for (var j = 0; j < textArray.length; j++) {
          var releventText = '', text = textArray[j];
          if (text && text.length > 0 && keywordRegex.test(text)) {
            releventText = text.replace(keywordRegex, function (match) { return '{start}' + match + '{end}'; });
            break;
          }
        }

        if (releventText !== '') {
          //trim relevent text to 70 words
          var releventTextArray = releventText.split(/\s+/);
          var startIndex = releventTextArray.IndexOfContaining('{start}');

          if (startIndex > 35 && (releventTextArray && releventTextArray.length > 70))
            releventText = releventTextArray.slice(startIndex - 35, startIndex + 35).join(' ');
          else
            releventText = releventTextArray.slice(0, 70).join(' ');

          //replace all instances of matched text with proper span tags
          var openTag = '<span class="search-results-highlight">', closeTag = '</span>';
          releventText = releventText.replace(new RegExp('{start}', 'g'), openTag);
          releventText = releventText.replace(new RegExp('{end}', 'g'), closeTag);
          results[i].ReleventText = releventText;
        } else {
          results[i].ReleventText = getDefaultText(textArray);
        }
      } else {
        results[i].ReleventText = getDefaultText(textArray);
      }
    }
  }

  function getDefaultText(textArray) {
    var longestText = textArray.reduce(function (a, b) { return a.length > b.length ? a : b; });
    var words = longestText.split(/\s+/);
    return words.length > 70 ? words.slice(0, 70).join(" ") : longestText;
  }

  function getKeywordsSearchedRegex(filters) {
    //return empty string if search no keyword search performed
    if (!filters.KeywordFilter) return '';

    if (filters.SynonymsFilter) {
      //remove matched keyword from keywords searched aka the synonyms and prepend the typed keyword first
      //that way it will be searched for first in get relevent text function
      var keywordsSearched = filters.SynonymsFilter;
      keywordsSearched = keywordsSearched.split(',');
      keywordsSearched.splice(keywordsSearched.indexOf(filters.KeywordFilter), 1);
      return buildKeywordRegex(filters.KeywordFilter + "|" + keywordsSearched.join('|'));
    }

    return buildKeywordRegex(filters.KeywordFilter.replace(/\s+/g, '|'));
  }

  function buildKeywordRegex(keywordsSearched) {
    //remove all lucene stop words
    var re = keywordsSearched.replace(/\b(a|an|and|are|as|at|be|but|by|for|if|in|into|is|it|no|not|of|on|or|such|that|the|their|then|there|these|they|this|to|was|will|with|\w)\||&\||\|\w$/gi, '');
    return new RegExp(re, 'gi');
  }

  Array.prototype.IndexOfContaining = function (value) {
    for (var i = 0; i < this.length; i++) {
      if (this[i].indexOf(value) > -1) { return i; }
    }
    return -1;
  };
}]);