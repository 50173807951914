bilzin.ng.controller("LargerStoryAreaController", ['$scope', '$http', '$element', 'queryStringService', function ($scope, $http, $element, queryStringService) {
  $scope.professionalGuids = $element.attr('data-professional-guids');
  $scope.practiceGuids = $element.attr('data-practice-guids');
  $scope.excludeGuids = $element.attr('data-exclude-guids');
  $scope.results = [];
  $scope.pageNum = 0;
  $scope.take = 0;
  $scope.skip = 0;
  $scope.type = 1;
  $scope.hasMore = false;
  $scope.isLoading = true;
  $scope.isLoadingMore = false;
  var activeKeyword = '';
  var queryString = '';

  $scope.setValues = function (jsonResult) {
    if ($scope.isLoadingMore) {
      $scope.results = $scope.results.concat(jsonResult.SearchResults);
      $scope.isLoadingMore = false;
    } else {
      $scope.results = jsonResult.SearchResults;
    }

    $scope.hasMore = jsonResult.HasMoreResults;
    $scope.resultsCount = jsonResult.ResultCount;
    $scope.take = jsonResult.SearchFilters.Take;
    $scope.skip = jsonResult.SearchFilters.Skip;
    $scope.isLoading = false;
  };

  $scope.viewMore = function () {
    $scope.performSearch(true, true);
  };

  $scope.performSearch = function (hasSearched, isLoadMore) {
    $scope.isLoading = true;

    if (isLoadMore) {
      $scope.isLoadingMore = true;
      $scope.pageNum++;
      $scope.skip = $scope.pageNum * $scope.take;
    } else {
      $scope.pageNum = 0;
      $scope.skip = 0;
    }

    queryString = '';
    queryString = queryStringService.addQueryString('skip', $scope.skip, queryString);
    queryString = queryStringService.addQueryString('take', $scope.take, queryString);
    queryString = queryStringService.addQueryString('type', $scope.type, queryString);

    for (var i = 0; i < $scope.practiceGuids.length; i++) {
      queryString = queryStringService.addQueryString('practices', $scope.practiceGuids[i], queryString);
    }
    for (var i = 0; i < $scope.professionalGuids.length; i++) {
      queryString = queryStringService.addQueryString('professionals', $scope.professionalGuids[i], queryString);
    }

    search();
  }

  function search() {
    $http.get('/api/sitecore/experiences/search?' + queryString)
      .success(function (response) {
        success(response);
      }).error(function (data, status, headers, config) {

      }
      );
  }

  function success(response) {
    $scope.hasMore = response.HasMoreResults;
    $scope.resultsCount = parseInt(response.ResultCount);
    $scope.setValues(response);
    $scope.hasSearched = $scope.hasSearched || $scope.selectedFilterObjects.length > 0 || activeKeyword.length > 0;
  }
}]);