bilzin.ng.controller("ViewMoreController", ['$scope', function ($scope) {
  $scope.results = [];
  $scope.pageNum = 0;
  $scope.take = 0;
  $scope.skip = 0;
  $scope.hasMore = false;
  var resultsCount = 0;
  var allResults = [];

  $scope.setValues = function (jsonResult) {
    resultsCount = jsonResult.Results.length;
    allResults = jsonResult.Results;
    $scope.take = jsonResult.PageSize;
    $scope.results = allResults.slice(0, $scope.take);
    $scope.pageNum++;
    $scope.skip = $scope.pageNum * $scope.take;
    $scope.hasMore = $scope.results.length < resultsCount;
  };

  $scope.viewMore = function () {
    $scope.pageNum++;
    $scope.results = $scope.results.concat(allResults.slice($scope.skip, ($scope.pageNum * $scope.take)));
    $scope.skip = $scope.pageNum * $scope.take;
    $scope.hasMore = $scope.results.length < resultsCount;
  };
}]);