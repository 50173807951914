bilzin.ng.directive("ngPanelScroll", ['$window', function ($window) {
  return function (scope, element, attrs) {
    var $element = $(element);
    var $content = $($element.find('.large-story-panel-copy'));

    angular.element($window).bind("scroll", function () {
      var $this = $(this);
      var top = $element.offset().top;
      var offsetBottom = this.pageYOffset + $this.height();
      var height = $element.height();
      var bottom = top + height;

      if (offsetBottom >= bottom) { //if (this.pageYOffset <= top && offsetBottom >= bottom) {
        $content.addClass('active');
      } else {
        $content.removeClass('active');
      }

      scope.$apply();
    });
  };
}]);