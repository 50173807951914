bilzin.ng.factory("watchUrlService", ['$location', function ($location) {
  return {
    //Watch for url changes and execute XHR request
    watchStateChange: function (currentScope, search) {

      var initializing = true;

      currentScope.$watch(function () { return $location.url(); }, function (url) {
        if (!initializing) {
          search();
        } else {
          //Do Nothing
          initializing = false;
        }
      });

    }
  };
}]);