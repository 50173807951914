bilzin.ng.controller('ProfessionalsController', ['$scope', '$http', '$element', '$location', 'queryStringService', 'domHelper', 'watchUrlService', function ($scope, $http, $element, $location, queryStringService, domHelper, watchUrlService) {
  $scope.keyword = '';
  $scope.letters = [];
  $scope.practices = [];
  $scope.schools = [];
  $scope.languages = [];
  $scope.lawyerLevels = [];
  $scope.selectedLetter = '';
  $scope.selectedPractice = {};
  $scope.selectedSchool = {};
  $scope.selectedLanguage = {};
  $scope.selectedLawyerLevel = {};
  $scope.selectedFilterObjects = [];
  $scope.results = [];
  $scope.hasMore = false;
  $scope.isLoading = true;
  $scope.isLoadingMore = false;
  $scope.skip = 0;
  $scope.take = 0;
  $scope.pageNum = 0;
  $scope.sort = 0;
  $scope.typeAheadResults = [];
  var viewAllLabel = $element.attr('data-view-all-lbl');
  var queryString = '';
  var activeKeyword = '';
  var searchContentTop = document.getElementById('peopleSearchContent').offsetTop;

  watchUrlService.watchStateChange($scope, search);

  $scope.setValues = function (jsonResult, isLanding) {
    $scope.letters = jsonResult.SearchFilters.LetterFilters;
    $scope.practices = jsonResult.SearchFilters.PracticeFilters;
    $scope.schools = jsonResult.SearchFilters.SchoolFilters;
    $scope.languages = jsonResult.SearchFilters.LanguageFilters;
    $scope.lawyerLevels = jsonResult.SearchFilters.LevelFilters;
    $scope.sort = jsonResult.SearchFilters.SelectedSortOption;

    //clearing keyword in code doesn't trigger onblur
    $('.searchbox-input').blur();
    updateFiltersFromQueryString($location.search(), jsonResult.SearchFilters);

    if ($scope.selectedFilterObjects.length > 0) {
      if ($scope.isLoadingMore) {
        $scope.results = $scope.results.concat(jsonResult.SearchResults);
        $scope.isLoadingMore = false;
      } else {
        $scope.results = jsonResult.SearchResults;
      }
    } else {
      $scope.results = [];
    }


    $scope.hasMore = jsonResult.HasMoreResults;
    $scope.take = jsonResult.SearchFilters.Take;
    $scope.skip = jsonResult.SearchFilters.Skip;
    $scope.isLoading = false;

    if ($scope.results.length > 0 && $scope.pageNum === 0) domHelper.scrollTo(searchContentTop, 0);
  }

  $scope.performKeywordSearch = function () {
    removeAllFilters();
    $scope.selectedLetter = '';
    $scope.selectedFilterObjects.push({ 'ID': $scope.keyword, 'Name': $scope.keyword, 'Type': 'keyword', 'IsSelected': true, 'IsEnabled': true });
    $scope.performSearch(true);
  }

  $scope.toggleLetter = function (letterFilter) {
    if (letterFilter.IsEnabled) {
      removeAllFilters();
      $scope.selectedLetter = letterFilter.Name;
      $scope.selectedFilterObjects.push({ 'ID': letterFilter.Name, 'Name': letterFilter.Name, 'Type': 'letter', 'IsSelected': true, 'IsEnabled': true });
      $scope.keyword = '';
      $scope.performSearch(true);
    }
  }

  $scope.practiceChange = function () {
    removeViewAll();
    if (isValidFilter($scope.selectedPractice)) {
      var idx = getTypeIndex('practice');
      if (idx > -1) $scope.selectedFilterObjects.splice(idx, 1);
      $scope.selectedFilterObjects.push({ 'ID': $scope.selectedPractice.ID, 'Name': $scope.selectedPractice.Name, 'Type': 'practice', 'IsSelected': true, 'IsEnabled': true });
      $scope.performSearch(true);
    }
  };

  $scope.schoolChange = function () {
    removeViewAll();
    if (isValidFilter($scope.selectedSchool)) {
      var idx = getTypeIndex('school');
      if (idx > -1) $scope.selectedFilterObjects.splice(idx, 1);
      $scope.selectedFilterObjects.push({ 'ID': $scope.selectedSchool.ID, 'Name': $scope.selectedSchool.Name, 'Type': 'school', 'IsSelected': true, 'IsEnabled': true });
      $scope.performSearch(true);
    }
  };

  $scope.languageChange = function () {
    removeViewAll();
    if (isValidFilter($scope.selectedLanguage)) {
      var idx = getTypeIndex('language');
      if (idx > -1) $scope.selectedFilterObjects.splice(idx, 1);
      $scope.selectedFilterObjects.push({ 'ID': $scope.selectedLanguage.ID, 'Name': $scope.selectedLanguage.Name, 'Type': 'language', 'IsSelected': true, 'IsEnabled': true });
      $scope.performSearch(true);
    }
  };

  $scope.lawyerLevelChange = function () {
    removeViewAll();
    if (isValidFilter($scope.selectedLawyerLevel)) {
      var idx = getTypeIndex('level');
      if (idx > -1) $scope.selectedFilterObjects.splice(idx, 1);
      $scope.selectedFilterObjects.push({ 'ID': $scope.selectedLawyerLevel.ID, 'Name': $scope.selectedLawyerLevel.Name, 'Type': 'level', 'IsSelected': true, 'IsEnabled': true });
      $scope.performSearch(true);
    }
  };

  $scope.viewAll = function () {
    removeAllFilters();
    $scope.keyword = '';
    $scope.selectedLetter = '';
    $scope.selectedFilterObjects.push({ 'ID': true, 'Name': viewAllLabel, 'Type': 'viewall', 'IsSelected': true, 'IsEnabled': true });
    $scope.performSearch(true);
  };

  $scope.removeFilter = function (filter) {
    var idx = $scope.selectedFilterObjects.indexOf(filter);
    if (idx > -1 && idx < $scope.selectedFilterObjects.length) {
      if (filter.Type === 'keyword') $scope.keyword = '';
      $scope.selectedFilterObjects.splice(idx, 1);
      $scope.performSearch(true);
    }
  };

  $scope.removeAllFilters = function () {
    $scope.selectedFilterObjects = [];
    removeAllFilters();
    $scope.keyword = '';
    $scope.typeAheadResults = [];
    activeKeyword = '';
    $scope.performSearch(true);
  };

  $scope.viewMore = function () { $scope.performSearch(true, true); };

  $scope.sortBy = function (sortType) {
    $scope.sort = sortType;
    $scope.performSearch(true);
  };

  $scope.performSearch = function (hasSearched, isLoadMore) {
    $scope.isLoading = true;
    activeKeyword = $scope.keyword;

    if (isLoadMore) {
      $scope.isLoadingMore = true;
      $scope.pageNum++;
      $scope.skip = $scope.pageNum * $scope.take;
    } else {
      $scope.pageNum = 0;
      $scope.skip = 0;
    }

    queryString = '';
    queryString = queryStringService.addQueryString('skip', $scope.skip, queryString);

    if ($scope.sort) {
      queryString = queryStringService.addQueryString('sort', $scope.sort, queryString);
    }

    for (var i = 0; i < $scope.selectedFilterObjects.length; i++) {
      queryString = queryStringService.addQueryString($scope.selectedFilterObjects[i].Type, $scope.selectedFilterObjects[i].ID, queryString);
    }

    $location.search(queryString);
  }

  $scope.typeAheadKeyup = function (keycode) {
    if (keycode !== 13 && $scope.keyword.length > 2) {
      PopulateTypeAhead();
    } else {
      $scope.typeAheadResults = [];
    }
    $(window).trigger('typeaheadchanged');
  };

  function PopulateTypeAhead() {
    $scope.typeAheadResults = [];

    $http.get('/api/sitecore/professionals/typeahead?' + queryStringService.toQueryString({keyword: $scope.keyword})).success(function (response) {
      $scope.typeAheadResults = response.map(function(element){
        element.Name = highlight(element.Name, $scope.keyword)
        return element;
      });
    }).error(function (data, status, headers, config) {

    });
  }

  function highlight(data, search) {
    return data.replace(new RegExp("(" + preg_quote(search) + ")", 'gi'), "<b>$1</b>");
  }

  function preg_quote(str) {
    return (str + '').replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, "\\$1");
  }

  function search() {
    //$(".load-container").show();
    $http.get('/api/sitecore/professionals/search?' + queryStringService.toQueryString($location.search())).success(function (response) {
      $scope.hasMore = response.HasMoreResults;
      $scope.setValues(response);
      $scope.hasSearched = $scope.hasSearched || $scope.selectedFilterObjects.length > 0 || activeKeyword.length > 0;
      //$(".load-container").hide();
    }).error(function (data, status, headers, config) {

    });
  }

  function removeAllFilters() {
    $scope.selectedFilterObjects = [];
    $scope.selectedPractice = {};
    $scope.selectedSchool = {};
    $scope.selectedLanguage = {};
    $scope.selectedLawyerLevel = {};
    $scope.skip = 0;
    $scope.typeAheadResults = [];
  }

  function isValidFilter(filter) {
    return (filter && filter.ID);
  }

  function getFilterOption(list, id) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].ID === id) return list[i];
    }
    return {};
  }

  function hasFilterType(type) {
    for (var i = 0; i < $scope.selectedFilterObjects.length; i++) {
      if ($scope.selectedFilterObjects[i].Type === type) return true;
    }
    return false;
  }

  function getTypeIndex(type) {
    for (var i = 0; i < $scope.selectedFilterObjects.length; i++) {
      if ($scope.selectedFilterObjects[i].Type === type) return i;
    }
    return -1;
  }

  function removeViewAll() {
    var idx = getTypeIndex('viewall');
    if (idx > -1) $scope.selectedFilterObjects.splice(idx, 1);
  }

  function updateFiltersFromQueryString(queryStringObject, searchFilters) {
    $scope.selectedFilterObjects = [];
    if ("keyword" in queryStringObject) {
      $scope.keyword = queryStringObject.keyword;
      if (!hasFilterType('keyword')) {
        $scope.selectedFilterObjects.push({ 'ID': $scope.keyword, 'Name': $scope.keyword, 'Type': 'keyword', 'IsSelected': true, 'IsEnabled': true });
      } else {
        $scope.selectedFilterObjects.push({ 'ID': $scope.keyword, 'Name': $scope.keyword, 'Type': 'keyword', 'IsSelected': true, 'IsEnabled': true });
      }
    }
    if ("letter" in queryStringObject) {
      if (!hasFilterType('letter')) {
        $scope.selectedFilterObjects.push({ 'ID': queryStringObject.letter, 'Name': queryStringObject.letter, 'Type': 'letter', 'IsSelected': true, 'IsEnabled': true });
      }
    }
    if ("practice" in queryStringObject) {
      if (!hasFilterType('practice')) {
        $scope.selectedPractice = searchFilters.SelectedPractice; //getFilterOption($scope.practices, queryStringObject.practice);
        if (isValidFilter($scope.selectedPractice)) {
          $scope.selectedFilterObjects.push({ 'ID': $scope.selectedPractice.ID, 'Name': $scope.selectedPractice.Name, 'Type': 'practice', 'IsSelected': true, 'IsEnabled': true });
        }
      }
    }
    if ("school" in queryStringObject) {
      if (!hasFilterType('school')) {
        $scope.selectedSchool = searchFilters.SelectedSchool; //getFilterOption($scope.schools, queryStringObject.school);
        if (isValidFilter($scope.selectedSchool)) {
          $scope.selectedFilterObjects.push({ 'ID': $scope.selectedSchool.ID, 'Name': $scope.selectedSchool.Name, 'Type': 'school', 'IsSelected': true, 'IsEnabled': true });
        }
      }
    }
    if ("language" in queryStringObject) {
      if (!hasFilterType('language')) {
        $scope.selectedLanguage = searchFilters.SelectedLanguage; //getFilterOption($scope.languages, queryStringObject.language);
        if (isValidFilter($scope.selectedLanguage)) {
          $scope.selectedFilterObjects.push({ 'ID': $scope.selectedLanguage.ID, 'Name': $scope.selectedLanguage.Name, 'Type': 'language', 'IsSelected': true, 'IsEnabled': true });
        }
      }
    }
    if ("level" in queryStringObject) {
      if (!hasFilterType('level')) {
        $scope.selectedLawyerLevel = searchFilters.SelectedLevel; //getFilterOption($scope.lawyerLevels, queryStringObject.level);
        if (isValidFilter($scope.selectedLawyerLevel)) {
          $scope.selectedFilterObjects.push({ 'ID': $scope.selectedLawyerLevel.ID, 'Name': $scope.selectedLawyerLevel.Name, 'Type': 'level', 'IsSelected': true, 'IsEnabled': true });
        }
      }
    }
    if ("viewall" in queryStringObject) {
      if (!hasFilterType('viewall')) {
        $scope.selectedFilterObjects.push({ 'ID': true, 'Name': viewAllLabel, 'Type': 'viewall', 'IsSelected': true, 'IsEnabled': true });
      }
    }
  }
}]);